<template>
  <div>
    <div class="flashcardHeader d-flex">
      <div class="pl-3 return">
        <router-link :to="{ name: 'MyVocabLists' }" style="color: #42a16a;">
          <i class="fas fa-chevron-left"></i>
        </router-link>
      </div>
      <div class="headerTitle">
        <span class="pr-4">{{ list.title }}</span>
      </div>
    </div>
    <div class="container content">
      <div class="list-description">
        {{list.intro}}
      </div>
      <div class="row">
        <div class="col-6 content-item">
          <div :class="startType===1?'list list-select': 'list'" @click="changeType(1)">
            <div class="list-title">{{$t("flashCards.Word List")}}</div>
          </div>
        </div>
        <div class="col-6 content-item">
          <div :class="startType===2?'list list-select': 'list'" @click="changeType(2)">
            <div class="list-title">{{$t("flashCards.Flash Cards")}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="levelButtons">
      <div class="d-flex flex-buttons" @click="review">
        <div style="width: 100%; color: #42a16a;" class="btnStyle">
          <div style="background-color: #42a16a;" class="btnBar"></div>
          <div><i class="fas fa-mountain"></i></div>
          <div class="btnClick">START</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import VocabList from "@/apis/myVocabLists";

export default {
  metaInfo() {
    return {
      title: this.list.title + " - Ivy-Way Academy"
    };
  },

  components: {
  },

  mixins: [],

  props: [],
  data() {
    return {
      startType: 1,
      list:{
        title:"",
        intro: "",
        vocabs: []
      }
    };
  },
  computed: {},
  mounted() {
    const main = document.getElementsByClassName("el-main");
    main[0].style.padding = "0";
    this.getList();
  },
  beforeDestroy() {
    if (window.innerWidth > 992) {
      // 離開此頁面後將 padding 恢復原狀
      const main = document.getElementsByClassName("el-main");
      main[0].style.padding = "100px 0px 0px 0px";
    } else {
      const main = document.getElementsByClassName("el-main");
      main[0].style.padding = "60px 0px 0px 0px";
    }
  },
  watch: {},
  methods: {
    changeType(type){
      this.startType = type;
    },
    review(){
      if(this.startType === 1){
        this.$router.push({
          name:"MyVocabListsList",
          query: {
            id: this.$route.query.id
          }
        });
      } else {
        this.$store.commit("flashcards/setCurrentSelectedVocabs", this.list.vocabs);
        this.$router.push({
          name: "MemorizeList",
          params: {
            title: this.list.title
          }
        });
      }
    },
    async getList(){
      const res = await VocabList.collectVocab(this.$route.query.id);
      this.list = res;
    }

  }
};
</script>

<style scoped>
.flashcardHeader {
  height: 55px;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  color: black;
  font-size: 28px;
}
.return:hover {
  opacity: 60%;
}
.content {
  padding-top:15px;
}
.headerTitle {
  text-align: center;
  width: 100%;
  font-weight: 600;
}
.list {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  height: 140px;
  padding: 10px;
  
}
.list-select {
  color: #fff;
  background: #42a16a;
  border: 1px solid #42a16a;
}
.list-title {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}
.list-description {
  /* height: 90px; */
  overflow: hidden;
  /* color: #fff; */
  font-size: 16px;
  word-break: break-word !important;
  margin-bottom: 20px;
}
.add-button{
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  height: 140px;
}
.content-item {
  margin-bottom: 15px;
}
.add-button span{
  font-size: 28px;
  line-height: 140px;
}
.levelButtons {
  background-color: white;
  position: fixed;
  width: 100%;
  bottom: 0;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}

.flex-buttons {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.btnBar {
  height: 10px;
}

.btnStyle {
  font-size: 26px;
  border: 1px solid #e0e0e0;
  padding-bottom: 12px;
  font-weight: 600;
}

.btnClick {
  font-size: 18px;
  line-height: 20px;
  padding-top: 0;
  cursor: pointer;
}


</style>
